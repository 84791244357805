import Alpine from 'alpinejs'
import collapse from '@alpinejs/collapse'
import 'mburger-webcomponent'
import "mmenu-js";

// import Swiper bundle with all modules installed
import Swiper from 'swiper/bundle';

// import styles bundle
import 'swiper/css/bundle';

const swiper = new Swiper('.swiper', {
  breakpoints: {
    // when window width is >= 320px
    320: {
      slidesPerView: 1,
      spaceBetween: 10
    },
    // when window width is >= 480px
    480: {
      slidesPerView: 2,
      spaceBetween: 10
    },
    // when window width is >= 640px
    640: {
      slidesPerView: 'auto',
      spaceBetween: 10
    }
  },
  // Navigation arrows
   navigation: {
    nextEl: '.swiper-next',
    prevEl: '.swiper-prev',
  },
});




import GLightbox from 'glightbox';//Lightbox
const lightbox = GLightbox({
  touchNavigation: true,
  selector: 'data-lightbox',
  videosWidth: '80vw'
});

import AOS from 'aos';
AOS.init({
  offset: 100, // offset (in px) from the original trigger point
  delay: 200, // values from 0 to 3000, with step 50ms
  duration: 750, // values from 0 to 3000, with step 50ms
  easing: 'ease', // default easing for AOS animations
  once: true,
  mirror: false,
});

Alpine.plugin(collapse)
window.Alpine = Alpine
Alpine.start()
